import { Backdrop, Box, Link, Paper, Typography } from '@mui/material';
import AppTypography from '../design/AppTypography';
import AppButton from '../design/Buttons/AppButton';
import { useNavigate } from 'react-router-dom';
import PATHS from '../router/paths';
import React from 'react';
import SmileLabel from '../design/Labels/SmileLabel';

interface OwnProps {
    isShowing: boolean;
}

export default function FirstScreen({ isShowing }: OwnProps) {
    const navigate = useNavigate();

    return (
        <Backdrop
            sx={{
                color: '#fff',
                background: 'rgba(46,121,141,0.8)',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                '& .MuiBackdrop-root': {
                    alignItems: 'flex-start',
                },
            }}
            open={isShowing}>
            <Paper
                elevation={3}
                sx={{
                    position: 'fixed',
                    top: 32,
                    left: 32,
                    padding: 3,
                    borderRadius: '16px',
                    width: 900,
                    display: 'flex',
                    paddingRight: 12,
                    flexDirection: 'column',
                    rowGap: 4,
                }}>
                <Box display={'flex'} columnGap={4}>
                    <SmileLabel />
                    <AppTypography
                        variant="font36"
                        fontWeight={'light'}
                        color="text.neutral9"
                        text={'Hey ho, lets go'}
                    />
                </Box>
                <AppTypography
                    variant="font20"
                    fontWeight={'regular'}
                    color="text.neutral9"
                    flexWrap={true}
                    text={
                        'Welcome to your new dashboard. \n' +
                        ' It looks a little empty so get started by creating your first Land Partner.'
                    }
                />
                <Box display={'flex'} columnGap={4}>
                    <AppButton
                        variant={'contained'}
                        title={'Step 1: Create a Land Partner'}
                        onClick={() =>
                            navigate(
                                `${PATHS.landpartners}/${PATHS.profiles}/${PATHS.create}`,
                            )
                        }
                    />
                    <AppButton
                        variant={'contained'}
                        title={'Step 2: Create a Lease'}
                        isDisabled={true}
                        onClick={() =>
                            navigate(
                                `${PATHS.landPortfolio}/${PATHS.manage}/${PATHS.create}`,
                            )
                        }
                    />
                </Box>
                <Typography
                    variant={'font14'}
                    fontWeight={'regular'}
                    color={'text.neutral9'}>
                    We are here to help – Don’t hesitate to
                    <Link href={'mailto:hopper@oaken.ag'} underline={'hover'}>
                        {' '}
                        reach out
                    </Link>{' '}
                    if you have any questions.
                </Typography>
            </Paper>
        </Backdrop>
    );
}
