import { SearchType } from './SearchCard';
import { Breadcrumbs, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import React from 'react';
import PATHS from '../../router/paths';
import theme from '../../styles/theme';
import BasedTooltip from '../../design/BasedTooltip';

interface OwnProps {
    type: SearchType;
    entityId: string;
    name?: string;
    notesEntityId?: string;
    noteOwner?: string;
}

const StyledLink = styled(Link)({
    fontSize: 11,
    color: theme.palette.brandLightTurq,
    '&:hover': {
        textDecoration: 'underline',
    },
});
interface BreadcrumbMapper {
    entityId: string;
    name?: string;
    noteOwner?: string;
}
export default function SearchCardBreadcrumbs({
    type,
    entityId,
    name,
    noteOwner,
}: OwnProps) {
    return (
        <BasedTooltip>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbMapper[type]({ entityId, name, noteOwner })}
            </Breadcrumbs>
        </BasedTooltip>
    );
}

const leaseBreadcrumbs = ({ entityId, name }: BreadcrumbMapper) => {
    return [
        <StyledLink
            key="1"
            color="inherit"
            reloadDocument
            to={`/${PATHS.landPortfolio}/${PATHS.manage}`}>
            Land Portfolio
        </StyledLink>,
        <StyledLink
            key="2"
            color="inherit"
            to={`/${PATHS.landPortfolio}/${PATHS.manage}/${entityId}`}>
            {name ? name : '  Lease Details'}
        </StyledLink>,
    ];
};
const landPartnerBreadcrumbs = ({ entityId, name }: BreadcrumbMapper) => [
    <StyledLink
        key="1"
        color="inherit"
        reloadDocument
        to={`/${PATHS.landpartners}/${PATHS.profiles}`}>
        Land Partners
    </StyledLink>,
    <StyledLink
        key="2"
        color="inherit"
        to={`/${PATHS.landpartners}/${PATHS.profiles}/${entityId}`}>
        {name ? name : '  Land Partner Details'}
    </StyledLink>,
];
const profileBreadcrumbs = ({ entityId, name }: BreadcrumbMapper) => [
    <StyledLink
        key="1"
        color="inherit"
        reloadDocument
        to={`/${PATHS.landpartners}/${PATHS.allProfiles}`}>
        All Profiles
    </StyledLink>,
    <StyledLink
        key="2"
        color="inherit"
        to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${entityId}`}>
        {name ? name : 'Profile Details'}
    </StyledLink>,
];
const leaseNoteBreadcrumbs = ({ name, noteOwner }: BreadcrumbMapper) => [
    <StyledLink
        key="1"
        color="inherit"
        reloadDocument
        to={`/${PATHS.landPortfolio}/${PATHS.manage}`}>
        Land Portfolio
    </StyledLink>,
    <StyledLink
        key="2"
        color="inherit"
        to={`/${PATHS.landPortfolio}/${PATHS.manage}/${noteOwner}`}>
        {name ? name : '  Lease Details'}
    </StyledLink>,
];
const noteBreadcrumbs = ({ name, noteOwner }: BreadcrumbMapper) => {
    return [
        <StyledLink
            key="1"
            color="inherit"
            reloadDocument
            to={`/${PATHS.landpartners}/${PATHS.allProfiles}`}>
            All Profiles
        </StyledLink>,
        <StyledLink
            key="2"
            color="inherit"
            to={`/${PATHS.landpartners}/${PATHS.allProfiles}/${noteOwner}/notes`}>
            {name ? name : 'Profile Details'}
        </StyledLink>,
    ];
};
const searchBreadcrumbs = () => {
    return [
        <StyledLink
            key="1"
            color="inherit"
            reloadDocument
            to={`/${PATHS.tasks}`}>
            Tasks
        </StyledLink>,
    ];
};

const breadcrumbMapper = {
    ALL: () => [],
    LEASE: leaseBreadcrumbs,
    PROFILE: profileBreadcrumbs,
    LAND_PARTNER: landPartnerBreadcrumbs,
    NOTE: noteBreadcrumbs,
    PAYMENT: () => [],
    TASK: searchBreadcrumbs,
    LEASE_NOTE: leaseNoteBreadcrumbs,
    DOCUMENT: () => [],
};
