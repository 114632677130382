import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import theme from '../../../styles/theme';
import PostMetaData, { MetaData } from './PostMetaData';
import { Box, Grid, Typography } from '@mui/material';

import { ActivityVariants, Attachment } from '../LandPartnerActivity';
import Loader from '../../../design/BaseLoader';
import PreviewModal from '../../../reusable/Modal/PreviewModal';
import {
    downloadFileFromURL,
    extractExtensionFromFileName,
} from '../../../utils/fileHandler';
import AttachmentDisplay from './AttachmentDisplay';
import GuestsList from './GuestsList';
import { dateTimeConverterFromUTC } from '../../../utils/dateFormat';
import EventPreview from './EventPreview';
import AppTypography from '../../../design/AppTypography';

export interface Post {
    id: string;
    landPartnerId: string;
    metadata: MetaData | null;
    message: string;
    location: string;
    guests: string[];
    eventDate: string;
    attachments?: Attachment[];
    comments?: Comment[];
    type: ActivityVariants;
    isLoading?: boolean;
}
export default function PostView({
    metadata,
    message,
    attachments,
    eventDate,
    guests,
    location,
    type,
    isLoading,
}: Post) {
    const { classes } = useStyles();
    const [isShowing, setIsShowing] = useState(false);
    const [file, setFile] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | undefined>();

    const handlePreviewOpen = async (url: string, fileName: string) => {
        setFileName(fileName);
        setFile(url);
        setIsShowing(true);
    };

    const handleDownload = async (url: string, fileName: string) => {
        downloadFileFromURL(url, fileName);
    };

    const handleClose = () => {
        setIsShowing(false);
        setFile(null);
    };

    if (isLoading) {
        return (
            <Grid
                container
                sx={{ height: '100%' }}
                alignItems={'center'}
                justifyContent={'center'}
                flexDirection={'column'}>
                <Loader />
            </Grid>
        );
    }

    return (
        <div className={classes.container}>
            {metadata && <PostMetaData {...metadata} />}
            {eventDate && dateTimeConverterFromUTC(eventDate) && (
                <Typography variant={'font12'} color={'text.neutral9'}>
                    {dateTimeConverterFromUTC(eventDate)}
                </Typography>
            )}
            {location && (
                <Box display={'flex'} flexDirection={'column'} rowGap={1}>
                    <Typography variant={'font12'} color={'text.neutral9'}>
                        Location
                    </Typography>
                    <Typography variant={'font14'} color={'text.neutral9'}>
                        {location}
                    </Typography>
                </Box>
            )}
            <EventPreview
                isHidden={type !== 'EVENT' && type !== 'EMAIL'}
                message={message}
            />
            <AppTypography
                flexWrap={true}
                isHidden={type === 'EVENT' || type === 'EMAIL'}
                variant={'font14'}
                text={message}
            />
            <GuestsList isHidden={!guests?.length} guests={guests} />
            {attachments?.map((attachment, index) => (
                <AttachmentDisplay
                    key={index}
                    attachment={attachment}
                    onPreviewOpen={() =>
                        handlePreviewOpen(attachment.link, attachment.name)
                    }
                    onDownload={() =>
                        handleDownload(attachment.link, attachment.name)
                    }
                />
            ))}

            {fileName && (
                <PreviewModal
                    extension={extractExtensionFromFileName(fileName)}
                    fileName={fileName}
                    file={file}
                    isShowing={isShowing}
                    onClose={handleClose}
                />
            )}
        </div>
    );
}

const useStyles = makeStyles()(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: theme.spacing(3),
    },
}));
