import { format } from 'date-fns';
import { Option } from '../CreateLeaseForm/WizardPageConfigs/leaseUploadConfig';
import { DateAmount } from './LeaseDetails';
import PATHS from '../../router/paths';
import LinkText from '../../design/Fields/LinkText';
import BasedTooltip from '../../design/BasedTooltip';
import { Box, Typography } from '@mui/material';
import InfoTooltip from '../../design/Tooltip/InfoTooltip';
import React from 'react';
import { Lease, LeaseFsaId } from '../../pages/LandPortfolio/TableConfig';

export function convertLeaseDetailDataToField(data: Lease) {
    const fieldNameString = data?.fsaIds.map((i) => i?.landName).join('; ');
    return [
        {
            title: 'Land Partners',
            value: data?.landowners?.length
                ? data.landowners.map(
                      (
                          i: Option,
                          index: number,
                          array: { id: string; name: string }[],
                      ) => (
                          <LinkText
                              key={i.id}
                              to={`/${PATHS.landpartners}/${PATHS.profiles}/${i.id}`}
                              text={
                                  i.name +
                                  (index < array.length - 1 ? ', ' : '')
                              }
                              fontWeight={'normal'}
                          />
                      ),
                  )
                : 'N/A',
        },
        {
            title: 'Tenant',
            value: data?.grower?.name || data?.grower,
            isHidden: !data?.grower,
        },
        {
            title: 'Lease Type',
            value: data?.type,
        },
        {
            title: 'Number of Payments per Year',
            value: data?.paymentSchedule.length || 'N/A',
        },
        {
            title: 'Payment Schedule',
            value: data?.paymentSchedule.length
                ? data.paymentSchedule?.join(' ,')
                : 'N/A',
        },
        {
            title: 'Agent',

            value: data?.agent?.id ? (
                <LinkText
                    key={data?.agent?.id}
                    to={`/${PATHS.landpartners}/${PATHS.profiles}/${data?.agent?.id}`}
                    text={data?.agent?.name}
                    fontWeight={'normal'}
                />
            ) : (
                'N/A'
            ),
        },
        {
            title: 'Lease Start Date',
            value: data?.startDate || 'N/A',
        },
        {
            title: 'Lease End Date',
            value: data?.endDate || 'N/A',
        },

        {
            title: 'FSA ID',
            value: data?.fsaIds.map((i: LeaseFsaId) => i?.fsaId).join(' ,'),
            isHidden: !data?.fsaIds.length,
            copied: true,
        },
        {
            title: 'Field Name',
            value: fieldNameString,
            isHidden: !data?.fieldName,
            copied: true,
        },
        {
            title: 'Crop Delivery',
            value: data?.cropDelivery,
            isHidden: !data?.cropDelivery,
        },
        {
            title: 'Crop Storage',
            value: data?.cropStorage,
            isHidden: !data?.cropStorage,
        },
        {
            title: 'Field Percentage',
            value: data?.fieldPercent,
            isHidden: !data?.fieldPercent,
        },
        {
            title: 'Acres',
            value: data?.totalAcres,
            isHidden: !data?.totalAcres,
        },
        {
            title: 'Total Payment',
            value: data?.totalPayment,
            isHidden: !data?.totalPayment,
        },
        {
            title: 'Auto Renewal',
            value: data?.autoRenewal === true ? 'Yes' : 'No',
            isHidden: false,
        },
        {
            title: 'Verbal Lease',
            value: data?.verbalLease === true ? 'Yes' : 'No',
            isHidden: false,
        },
        {
            title: 'FSA POA',
            value: data?.fsaPoa === true ? 'Yes' : 'No',
            isHidden: false,
        },
        {
            title: 'Bonus Payment Date',
            value: data?.bonusSchedule,
            isHidden: !data?.bonusSchedule,
        },
        {
            title: 'Grace Period Date',
            value: data?.gracePeriodDate,
            isHidden: !data?.gracePeriodDate,
        },
    ];
}

export function extractMonthsAndDayFromDateAmount(obj: DateAmount[]) {
    return obj.reduce(
        (acc, i) => {
            const formattedDate = format(
                new Date(i.paymentDate),
                'MMM dd',
            ) as string;
            // eslint-disable-next-line
            //@ts-ignore
            acc.data[formattedDate] = i.amount;
            // eslint-disable-next-line
            //@ts-ignore
            acc.keys.push(formattedDate);
            return acc;
        },
        { data: {}, keys: [] },
    );
}
export const showLandPartnersName = (landowners: string[] | string) => {
    if (typeof landowners !== 'string' && landowners?.length) {
        return (
            <BasedTooltip text={landowners.join(' ,')}>
                <Box display={'flex'} gap={1}>
                    <Typography
                        sx={{ textAlign: 'center' }}
                        variant={'font14'}
                        fontWeight={'medium'}>
                        {landowners[0]}
                    </Typography>
                    <Typography
                        sx={{ textAlign: 'center' }}
                        variant={'font14'}
                        fontWeight={'medium'}>
                        {landowners.length > 1 && `+${landowners.length - 1}`}
                    </Typography>
                    {landowners.length > 1 && (
                        <InfoTooltip
                            text={landowners.join(' ,')}
                            position={'top'}
                        />
                    )}
                </Box>
            </BasedTooltip>
        );
    }
    return (
        <Typography
            sx={{ textAlign: 'center' }}
            variant={'font14'}
            fontWeight={'medium'}>
            {landowners}
        </Typography>
    );
};
